<template>
    <b-overlay>
        <b-card>
            <div>
                <b-form-checkbox inline v-model="showInPlans" class="float-left">Prikaži tudi zalogo v planih</b-form-checkbox>
                <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm" class="float-right col-sm-2 mb-1"/>
                <b-table striped responsive bordered hover :items="filtered" :fields="fields" :filter="search"
                         :per-page="perPage" :current-page="currentPage" filter-debounce="500">
                    <template slot="top-row" slot-scope="{ fields }">
                        <b-td v-for="field in fields" :key="field.key">
                            <b-form-input v-if="field.key != 'actions'" size="sm" v-model="filters[field.key]"
                                          :placeholder="field.label"/>
                        </b-td>
                    </template>
                    <template v-slot:cell(status)="row">
                        <b-badge v-if="row.item.status == 'v planu'" variant="warning">{{ row.item.status }}</b-badge>
                        <b-badge v-else variant="danger">{{ row.item.status }}</b-badge>
                    </template>
                    <template #cell(actions)="row">
                        <div class="d-flex">
                            <b-button class="mr-1 float-left" @click="$refs.ZalogaItemModal.open(row.item)" variant="success">
                                <fa icon="eye"/>
                            </b-button>
                            <b-button class="float-right" v-if="row.item.status == 'na čakanju'" @click="addNarocilo(row.item)">
                                <fa icon="plus-square"/>
                            </b-button>
                        </div>
                    </template>
                </b-table>
                <b-row>
                    <b-col class="float-left">
                        <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
                            <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key"
                                             @click="setPerPage(item)">
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col sm="7" md="6" class="float-right">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"/>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <b-modal title="Dodaj naročilo v plan" v-model="addModalActive" centered hide-footer>
            <template #default>
                <b-row>
                    <p class="col-2 font-weight-bold">ID:</p>
                    <p>{{ selectedItem.id }}</p>
                </b-row>
                <b-row>
                    <p class="col-2 font-weight-bold">Stranka:</p>
                    <p>{{ selectedItem.client }}</p>
                </b-row>
                <b-form-group label="Datum" rules="required" label-for="add-date">
                    <b-datepicker id="add-date" name="add-date" v-model="selectedDate" :date-format-options="dateFormat"
                                  start-weekday="1" label-help="" label-no-date-selected="Izberi datum"/>
                </b-form-group>
                <b-button variant="primary">Dodaj</b-button>
            </template>
        </b-modal>

        <ZalogaItemModal ref="ZalogaItemModal"/>
    </b-overlay>
</template>

<script>
    import ZalogaItemModal from '@/views/ZalogaItem'
    import fuzzysort from 'fuzzysort'

    export default {
        components: {
            ZalogaItemModal
        },

        data() {
            return {
                addModalActive: false,
                showInPlans: false,
                selectedDate: new Date().toISOString().substr(0, 10),
                dateFormat: {'year': 'numeric', 'month': 'numeric', 'day': 'numeric'},
                selectedItem: {},
                stock: [],
                stock_not_in_plans : [],
                search: '',
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                selected: {},
                fields: [
                    {key: 'material_type.id', label: 'Material šifra', sortable: true, class: 'text-center'},
                    {key: 'material_type.material', label: 'Material naziv', sortable: true, class: 'text-center'},
                    {key: 'material_type.saw_group_id', label: 'Skupina za razrez', sortable: true, class: 'text-center'},
                    {key: 'length', label: 'Dolžina', sortable: true, class: 'text-center'},
                    {key: 'location', label: 'Lokacija', sortable: true, class: 'text-center'},
                    {key: 'quantity', label: 'Količina', sortable: true, class: 'text-center'},
                    {key: 'actions', label: ''}
                ],
                filters: {'material_type': '', 'length': '', 'quantity': ''}
            }
        },
        methods: {
            getStock() {
                const thisIns = this
                this.$http.get('/api/v1/user/stock')
                    .then(function(response) {
                        thisIns.stock = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
                this.$http.get('/api/v1/user/stock_not_in_plans')
                    .then(function(response) {
                        thisIns.stock_not_in_plans = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
            },
            setPerPage(item) {
                this.perPage = item
            },
            flatten(obj, prefix = [], current = {}) {
                if (typeof (obj) === 'object' && obj !== null) {
                    for (const key of Object.keys(obj)) {
                        this.flatten(obj[key], prefix.concat(key), current)
                    }
                } else {
                    current[prefix.join('.')] = obj
                }
                return current
            }
        },
        computed: {
            totalRows() {
                return this.filtered.length
            },
            planningStatus() {
                return this.$store.state.user.planning
            },
            filtered() {
                let selected = []
                if (this.showInPlans) {
                    selected  = this.stock
                } else {
                    selected = this.stock_not_in_plans
                }
                const filtered = selected.filter(item => {
                    return Object.keys(this.filters).every(key => String(this.flatten(item)[key]).toLowerCase().includes(this.filters[key].toLowerCase()) || fuzzysort.single(this.filters[key].toLowerCase(), String(this.flatten(item)[key]).toLowerCase()) !== null)
                })
                return filtered.length > 0 ? filtered : [
                    {
                        id: '',
                        client: '',
                        date_added: '',
                        date_due: '',
                        quantity: '',
                        status: ''
                    }
                ]
            }
        },
        watch: {
            planningStatus() {
                this.getStock()
            }
        },
        created() {
            this.getStock()
        }
    }
</script>
