<template>
    <b-modal title="Podrobnosti kosa" v-model="stockModalActive" size="lg" centered hide-footer>
        <b-card>
            <table>
                <tr>
                    <th class="pb-50">ID</th>
                    <td class="pb-50 pl-1">{{ stock.id }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Dolžina</th>
                    <td class="pb-50 pl-1">{{ stock.length }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Material</th>
                    <td class="pb-50 pl-1">{{ stock.material_type.material }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Material šifra</th>
                    <td class="pb-50 pl-1">{{ stock.material_type.id }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Količina</th>
                    <td class="pb-50 pl-1">{{ stock.quantity }}</td>
                </tr>
            </table>
        </b-card>
    </b-modal>
</template>

<script>
    export default {
        data() {
            return {
                stockModalActive: false,
                stock: {material_type: {}},
                search: '',
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                totalRows: 1,
                selected: {},
                fields: [
                    {key: 'length', label: 'Dolžina', sortable: true, class: 'text-center'},
                    {key: 'quantity', label: 'Količina', sortable: true, class: 'text-center'}
                ],
                filters: {length: '', material_type: ''}
            }
        },
        methods: {
            open(item) {
                if (item !== null) {
                    this.stock = item
                    this.stockModalActive = true
                }
            },
            setPerPage(item) {
                this.perPage = item
            }
        }
    }
</script>
